/*eslint-disable*/
import React from "react";
// import DeleteIcon from "@material-ui/icons/Delete";
// import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import Link from "@components/Link";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "@components/CustomDropdown/CustomDropdown.js";
import Button from "@components/CustomButtons/Button.js";

import styles from "@assets/jss/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="home-tooltip"
          title="首页"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="/"
            target="_self"
            className={classes.navLink}
          >
            Home
          </Button>
        </Tooltip>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Tooltip
          id="courses-tooltip"
          title="课程介绍"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="/courses"
            target="_self"
            className={classes.navLink}
          >
            courses
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="teachers-tooltip"
          title="师资介绍"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="/teachers"
            target="_self"
            className={classes.navLink}
          >
            teachers
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="news-tooltip"
          title="活动及资讯"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="/news"
            target="_self"
            className={classes.navLink}
          >
            News
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="gallery-tooltip"
          title="舞蹈表演照片和视频"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="/gallery"
            target="_self"
            className={classes.navLink}
          >
            Gallery
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="about-tooltip"
          title="关于小舞花"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="/about"
            target="_self"
            className={classes.navLink}
          >
            About Us
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="contact-tooltip"
          title="联系我们"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="/contact"
            target="_self"
            className={classes.navLink}
          >
            Contact Us
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}

// <ListItem className={classes.listItem}>
//         <Tooltip
//           id="curricula-tooltip"
//           title="课程设置"
//           placement="top"
//           classes={{ tooltip: classes.tooltip }}
//         >
//           <Button
//             color="transparent"
//             href="/curricula"
//             target="_self"
//             className={classes.navLink}
//           >
//             Curricula
//           </Button>
//         </Tooltip>
//       </ListItem>