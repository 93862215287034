import React from 'react'
import Header from '@components/Header/Header'
import Footer from '@components/Footer/Footer'
import HeaderLinks from '@components/Header/HeaderLinks'
import Copyright from '@components/Copyright'
import CssBaseline from '@material-ui/core/CssBaseline'

const Layout = (props) => {
  const { title, children } = props
  const { ...rest } = props

  // if (location.pathname === rootPath || location.pathname === blogPath) {
  return (
    <>
      <CssBaseline />
      <div style={{
        backgroundColor: "#eeeeee",
      }}>
        
        <Header
          color="transparent"
          brand={title}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <main>{children}</main>
        <Footer
          whiteFont
          rightChildren={
            <div>
              <div style={{
                marginBottom: "12px",
                fontSize: "0.9em"
              }}>
                小舞花舞蹈学院 <br />
                103 - 6125 Sussex Ave <br />Burnaby BC
              </div>
              <Copyright />
            </div>
          }
        />
      </div>
    </>
  )
}

export default Layout
